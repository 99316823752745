<template>

        <span>

          <v-toolbar flat color="white">
            <v-toolbar-title class="lime--text lighten-1">
              <img v-if="branch.empresa" :src="`${base_url}upload/company/${branch.empresa.comp_logo}`" height="40">
            </v-toolbar-title>
            <v-divider
              class="mx-2"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
              <template>
                <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'branchs'}"><v-icon dark>keyboard_arrow_left</v-icon> Voltar</v-btn>
              </template>
          </v-toolbar>

          <table id="table-detalhe" class="table table-bordered table-condensed">
            <tbody>
            <tr>
                <th width="10%">Titulo:</th>
                <td width="40%" colspan="2">{{ branch.comp_b_titulo }}</td>
                <th width="10%">Empresa:</th>
                <td v-if="branch.empresa" width="90%" colspan="2" >{{ branch.empresa.comp_fantasia }}</td>
            </tr>
            <tr>
                <th width="10%">CNPJ:</th>
                <td width="40%" colspan="2">{{ branch.comp_b_cnpj }}</td>
                <th width="10%">I. E.:</th>
                <td width="40%" colspan="2">{{ branch.comp_b_ie }}</td>
            </tr>
            <tr>
                <th width="10%">Endereço:</th>
                <td width="40%" colspan="2">{{ branch.comp_b_endereco }}</td>
                <th width="10%">Número:</th>
                <td width="40%" colspan="2">{{ branch.comp_b_numero }}</td>
            </tr>
            <tr>
                <th width="10%">Bairro:</th>
                <td colspan="2">{{ branch.comp_b_bairro }}</td>
                <th width="10%">Cidade:</th>
                <td colspan="2">{{ branch.comp_b_cidade }}</td>
            </tr>
            <tr>
                <th width="10%">Estado:</th>
                <td colspan="2">{{ branch.comp_b_estado }}</td>
                <th width="10%">CEP:</th>
                <td colspan="2">{{ branch.comp_b_cep }}</td>
            </tr>
            <tr>
                <th width="10%">Complemento</th>
                <td colspan="2">{{ branch.comp_b_complemento }}</td>
                <th width="10%">Email:</th>
                <td colspan="2">{{ branch.comp_b_email }}</td>
            </tr>
            <tr>
                <th width="10%">Telefone 1</th>
                <td colspan="2">({{ branch.comp_b_prefixo }}) {{ branch.comp_b_fone_1 }}</td>
                <th width="10%">Telefone 2</th>
                <td colspan="2">({{ branch.comp_b_prefixo }}) {{ branch.comp_b_fone_2 }}</td>
            </tr>
            <tr>
                <th width="10%">Telefone 3:</th>
                <td colspan="2">({{ branch.comp_b_prefixo }}) {{ branch.comp_b_fone_3 }}</td>
                <th width="10%">Celular:</th>
                <td colspan="2">({{ branch.comp_b_refixo }}) {{ branch.comp_b_celular }}</td>
            </tr>
            <tr>
                <th width="10%">Facebook:</th>
                <td colspan="2">{{ branch.comp_b_link_facebook }}</td>
                <th width="10%">Instagram:</th>
                <td colspan="2">{{ branch.comp_b_link_instagram }}</td>
            </tr>
            <tr>
                <th width="10%">Twitter:</th>
                <td width="40%" colspan="2">{{branch.comp_b_link_twitter}}</td>
                <th width="10%">Status:</th>
                <td width="40%" colspan="5">{{ (branch.comp_b_status == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Mapa:</th>
                <td width="90%" colspan="5">{{branch.comp_b_mapa}}</td>
            </tr>
            <tr>
            </tr>
            </tbody>
        </table>
          
        </span>


</template>

<script>
import {URL_BASE} from '../../../config/configs'

export default {
  created() {
    this.loadCompanyBranch()
  },
  name: "DetailBranchComponent",
  props: {
    comp_b_id: {
      require: true
    },
  },
  data () {
      return {
        branch: {},
        base_url: URL_BASE
      }
  },
  methods: {
    loadCompanyBranch() {
      this.$store.dispatch('loadCompanyBranch', this.comp_b_id)
        .then(response => {
          this.branch = response
        })
        .catch(error => {
          this.$swal({
            position: "center",
            icon: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
  }
};
</script>

<style scoped>
.avatar{
  height: 25px;
}
.logo{
  height: 50px;
}
</style>
